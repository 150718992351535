import { Injectable } from '@angular/core';
import { createStore, setProp, setProps, withProps  } from '@ngneat/elf';
import { ViewInterface } from '../../shared/interfaces/view.interface';

const store = createStore(
  { name: 'currentView ' },
  withProps<ViewInterface>({
    base_url: '',
    display_name: '',
    mobile_version_available: false,
    currentTab: 0,
  }),
);

@Injectable({
  providedIn: 'root',
})
export class CurrentViewStore {
  updateCurrentView(
    currentView: ViewInterface,
  ): void {
    const previousView = this.getCurrentView();
    const isBaseUrlChanged: boolean = currentView.base_url !== previousView?.base_url;

    if (previousView?.base_url === '' || isBaseUrlChanged) {
      // initial robot load || changed page
      store.update((state) => ({
        ...state,
        ...currentView,
      }));
    } else if (!isBaseUrlChanged) {
      if (currentView.currentTab !== undefined && currentView.currentTab !== previousView?.currentTab) {
        //  just tab change
        store.update(
          setProp('currentTab', currentView.currentTab),
        );
      } else if (currentView.currentTab === undefined && previousView?.currentTab !== undefined) {
        // navigated to another robot with same base url - we don't set currentTab to keep the previous value after page reloads
        store.update(
          setProps({
            base_url: currentView.base_url,
            display_name: currentView.display_name,
            mobile_version_available: currentView.mobile_version_available,
          }),
        );
      }
    }
  }

  getCurrentView(): ViewInterface | null {
    return store.getValue();
  }

  getCurrentTab(): number | undefined {
    const tab = +(store.getValue().currentTab as any);
    return Number.isNaN(tab) ? undefined : tab;
  }
}

